import * as React from 'react'

export function TextWithLineBreaks({ children }: { children: string }) {
	return (
		<p className="text-xs md:text-sm">
			{children.split('\n').map((line, index) => (
				<React.Fragment key={index}>
					{line}
					<br />
				</React.Fragment>
			))}
		</p>
	)
}
